import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/government-case-study",
  "date": "22nd November 2024",
  "title": "An Australian Government user",
  "summary": "The story of how a client moved to and hosts on Skpr.",
  "author": "Griffyn Heels",
  "tag": "Case Study",
  "tagColor": "green",
  "tags": [{
    "name": "case study"
  }, {
    "name": "australian government"
  }, {
    "name": "hosting"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Overview`}</h2>
    <p>{`A multiple-site migration to a single hosting platform has improved and streamlined the developer experience for this government client.`}</p>
    <h2>{`The challenges`}</h2>
    <p>{`Before engaging our services, this client hosted several Drupal applications - across Drupal 7, 8 and 9 - on multiple Drupal hosting providers.`}</p>
    <p>{`Their internal team maintains both a corporate site and several smaller applications that provide public services. This task is challenging for a relatively small team to tackle without additional support.`}</p>
    <h2>{`The solutions`}</h2>
    <p>{`We began by migrating their main Drupal sites onto Skpr, our scalable and secure hosting platform. This strategic move to one managed AWS cluster enhanced oversight of all their applications, streamlined developer tooling, and enabled real-time autoscaling, thereby significantly improving their website management.`}</p>
    <p>{`To meet their requirements, we supported multiple versions of Drupal and several external integrations, configured CDNs, and provided monitoring and reporting dashboards, scalable storage, WAF, and training.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/government-case-study/diagram.png",
        "alt": "Image of the Skpr stack"
      }}></img></p>
    <p>{`Our commitment to the client extends beyond the initial setup and migration. We provide continuous hosting support, maintenance, and ad-hoc development services, effectively acting as an extension of their in-house Drupal development team. `}</p>
    <p>{`Utilising our deep knowledge of deployment pipelines, our team were able to implement a continuous integration/deployment (CI/CD) that allows the client to safely deploy with confidence. `}</p>
    <p>{`This ongoing assistance has been instrumental in addressing their immediate and long-term hosting requirements and plans, further solidifying our partnership.`}</p>
    <h2>{`Facilitating a tight timeline`}</h2>
    <p>{`We followed a tight four-week schedule driven by the end of their previous hosting arrangement. In week one, we ran a Discovery workshop and provisioned the cluster and environments.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/government-case-study/timeline.jpg",
        "alt": "Image of the four week timeline"
      }}></img></p>
    <p>{`Week two saw the start of the migration, with an initial run-through and feedback from the client. We also set up their CI/CD pipeline, upgraded the local development stack, and ran training and onboarding sessions, which improved their development tools and experience. `}</p>
    <p>{`The training continued in week three as we moved to final testing, approvals and go-live preparation tasks.`}</p>
    <p>{`In week four, we received go-live approval and launched their sites on Skpr. From there, we moved straight into ongoing support and business-as-usual processes.`}</p>
    <h2>{`The outcomes`}</h2>
    <p>{`The client team was pleased with the results of the migration and hosting solution. We delivered the project on time and before the previous supplier’s cut-off date while also keeping to their budget.`}</p>
    <p>{`We continue to enjoy a positive and productive relationship with them through annually renewed contracts. Nearly three years after the initial migration, additional applications are being developed and launched on their Skpr cluster in direct consultation with our developers at PreviousNext.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      